'use strict';

Gri.module({
  name: 'button-search',
  ieVersion: null,
  $el: $('.button-search'),
  container: '.button-search',
  fn: function () {
    tofas.buttonSearch = {
      el: $('.button-search'),
      searchAlpha: $('.search-alpha'),
      headerAlpha: $('.header-alpha'),
      init: function() {
        tofas.buttonSearch.click();
      },
      click: function() {
        tofas.buttonSearch.el.on('click', function(){
          var icon = $(this).find('i');
          if(icon.hasClass('icon-search')){
            tofas.buttonSearch.open(icon);
          }else{
            tofas.buttonSearch.close(icon);
          }
        });
      },
      open: function(selector) {
        selector.removeClass('icon-search').addClass('icon-close');
        tofas.buttonSearch.searchAlpha.addClass('opened');
        if(tofas.buttonSearch.headerAlpha.length > 0) {
          tofas.buttonSearch.headerAlpha.addClass('search');
        }
      },
      close: function(selector){
				$('.search-alpha').find('input').val("");
        selector.removeClass('icon-close').addClass('icon-search');
				tofas.buttonSearch.searchAlpha.removeClass('opened');
        if(tofas.buttonSearch.headerAlpha.length > 0) {
          tofas.buttonSearch.headerAlpha.removeClass('search');
        }
      },
      menu: function(val) {
        if(val == "open") {
          tofas.buttonSearch.searchAlpha.addClass('menu');
        }else{
          tofas.buttonSearch.searchAlpha.removeClass('menu');
        }
      }
    }

    /**/

    tofas.buttonSearch.init();

  }
});
